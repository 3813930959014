
export default {
  props: {
    publication: {
      type: Object,
      default: null,
    },
  },
  computed: {
    publicationType() {
      let type
      const modelId = this.publication.modelId || this.publication.reference.modelId
    switch (modelId) {
      case 'fa9af868-5af3-49db-8b55-ff1155b2a53b': // program
        type = 'Kijken'
        break
      case 'a45ca4c5-4010-4608-8174-94354e618901': // podcast
        type = 'Luisteren'
        break
      default: // '2da88617-d86a-4763-886a-97ee47db6344' -> article -> default geen type tekst
        type = ''
        break
    }
      return type
    },
  },
}
