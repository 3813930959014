import { render, staticRenderFns } from "./index.vue?vue&type=template&id=278a9920"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BannerPentecost: require('/app/components/BannerPentecost.vue').default,HomeCard: require('/app/components/HomeCard/index.vue').default,HomeListItem: require('/app/components/HomeListItem/HomeListItem.vue').default})
