
import { mapGetters, mapActions } from 'vuex'
import { HEADER_THEME_TABS, TOPICS } from '~/constants'
import { metaDataMixin, eoRendererMixin } from '~/mixins'

const GENERAL_CHANNEL_STORE = 'general-channels'

const pinnedTopics = [
  "oog-voor-anderen",
  'geloven-en-vragen',
  'hier-en-nu',
  "afscheid-en-rouw",
]

export default {
  mixins: [
    eoRendererMixin,
    metaDataMixin,
  ],
  async asyncData ({ $api, $config }) {
    const [
      { data: { items: allArticles = {}, meta: { total = 0 } = {} }},
      { data: { items: recommended = {} }},
      { data: { items: popular = {} }},
      { data: { item: playlist = {} }},
      { data: { items: ctas = [] }},
    ] = await Promise.all([
      $api.mixedarticles.getAll({
        excludedDomains: "beam-eo,lazarus-eo,ditisdedag-eo,bijbelopen-eo",
        offset: 0,
        limit: 20,
      }),
      $api.teasers.getAll({
        channel: "general-recommended",
        offset: 0,
        limit: 5,
      }),
      $api.teasers.getAll({
        channel: "general-popular",
        offset: 0,
        limit: 5,
      }),
      $api.playlists.getOne('1be6365d-71be-4e8d-b3c0-38030edd7ee5', {
        domain: $config.preprEnvironmentDomainEo,
      }),
      $api.calltoactions.getAll({
        channel: 'general-event',
        domain: $config.preprEnvironmentDomainEo,
        limit: 1,
      }),
    ])

    const getTopicArticle = async (topic) => {
      const {
        data: { items: articles = {} },
      } = await $api.articles.getAll(
        {
          excludedDomains: "beam-eo,bijbelopen-eo",
          offset: 0,
          limit: 1,
          topic: TOPICS[topic].id,
        },
      )

      return articles
    }

    const topicArticles = await Promise.all(
      pinnedTopics.map(async topic => {
        return await getTopicArticle(topic)
      }),
    ).then((response) => {
      // After all promises are resolved, flatten the responses
      return response.flat()
    })

    // Filter out existing articles from topicArticles
    const articles = allArticles.filter((article) => {
      const topicArticleIds = topicArticles.flatMap(tArticle => tArticle.id)
      return !topicArticleIds.includes(article.id)
    })

    const cta = ctas[0]

    return {
      playlist,
      cta,
      recommended,
      popular,
      articles,
      topicArticles,
      hasMoreArticles: total > articles.length,
    }
  },
  data () {
    return {
      articles: [],
      playlist: null,
      topics: TOPICS,
      cta: null,
      recommended: null,
      popular: null,
      topicResults: {},
      headerThemeTabs: HEADER_THEME_TABS,
      activeTabsList: 0,
      hasMoreArticles: false,
      seo: {
        title: 'Home',
        description: 'Alles over de televisie- en radioprogramma\'s van de EO, websites en sociale media, persberichten, algemene informatie over de omroep, missie en ledenvereniging.',
      },
    }
  },
  computed: {
    maxNumArticles () {
      return this.articles.length - (this.articles.length % 3)
    },
    generalPodcasts () {
      return this.$store.getters[`${GENERAL_CHANNEL_STORE}/podcasts`]()
    },
    socialCookiesAllowed () {
      return this.$store.state.cookieConsent.social
    },
    ...mapGetters('loading', ['isLoading']),
  },
  methods: {
    ...mapActions([
      'teasers/proxyAction',
      'programs/proxyAction',
    ]),
    async fetchMoreArticles () {
      const {
        data: { items: newArticles = {}, meta: { total = 0 } = {} },
      } = await this.$api.mixedarticles.getAll(
        {
          excludedDomains: "beam-eo,lazarus-eo,ditisdedag-eo,bijbelopen-eo",
          offset: this.articles.length,
          limit: 12,
        },
      )

      this.hasMoreArticles = total > this.articles.length
      this.articles = [...this.articles, ...this.filterArticles(newArticles, this.articles)]
    },
    filterArticles(newArticles, oldArticles) {
        return newArticles.filter((article) => {
        const articleIds = oldArticles.flatMap(oldArticle => oldArticle.id)
        return !articleIds.includes(article.id)
      })
    },
  },
}
