
export default {
  name: 'BannerPentecost',
  data () {
    return {
      isStartInPast: true,
      isEndInFuture: true,
    }
  },
  created () {
    this.$moment.relativeTimeThreshold('ss', 0)
    const start = this.$moment('2024-05-09 00:00:00')
    this.isStartInPast = start.diff(this.$moment(), 'minutes', true) <= 0
    const end = this.$moment('2024-05-20 00:00:00')
    this.isEndInFuture = end.diff(this.$moment(), 'minutes', true) > 0
  },
}
